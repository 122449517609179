$(function(){
    let secondarySlider  = new Splide( '#secondary-slider', {
        fixedWidth: 100,
        height    : 3 / 4 * 100,
		rewind    : true,
		gap       : 10,
        pagination: false,
        cover     : true,
        focus     : 'center',
        isNavigation: true,
    } ).mount();

    let primarySlider =  new Splide( '#primary-slider', {
		type       : 'fade',
        heightRatio: 3 / 4,
        pagination : false,
        arrows     : false
    });

    primarySlider.sync(secondarySlider).mount();
    
});